import React, { useState, useContext } from "react";

import { EditorContext } from "../Context/editor.jsx";
import Overlay from "../Overlay/";
import ShortcutHelp from "../ShortcutHelp/";
import { useHotkeys } from "react-hotkeys-hook";
import { useAuthStatus } from "../Context/kv/useAuthStatus";

import "./styles.css";

const Assistant = () => {
  const { isLoggedIn } = useAuthStatus("editor");

  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  const [state, dispatch] = useContext(EditorContext);

  const handleButtonClick = () => {
    setIsOverlayVisible(true);
  };

  useHotkeys(
    "meta+k",
    () => {
      handleButtonClick(true);
    },
    {
      enableOnFormTags: true,
    }
  );

  const handleOverlayClose = (value) => {
    if (value !== null) {
      dispatch({
        type: "REPLACE_SELECTION",
        payload: { text: value, selected: true },
      });
      dispatch({ type: "SET_FOCUS", payload: true });
    } else {
      dispatch({ type: "SET_FOCUS", payload: true });
    }
    setIsOverlayVisible(false);
  };

  const selectedText = state.text.slice(
    state.selectionStart,
    state.selectionEnd
  );

  return (
    <div className="assistantOLD">
      <div className="assistantOLD__button">
        <button
          className="button"
          onClick={handleButtonClick}
          disabled={!isLoggedIn}
        >
          ✨ Assistant
          <ShortcutHelp command="meta+k" />
        </button>
      </div>
      {!isLoggedIn && <p className="small">Please login to use Assistant</p>}
      {isOverlayVisible && (
        <>
          <Overlay selectedText={selectedText} onClose={handleOverlayClose} />
        </>
      )}
    </div>
  );
};

export default Assistant;
