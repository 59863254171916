// Import necessary modules
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Get the root DOM node where the React application will be mounted
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// Render the App component into the root DOM node
// The App component is wrapped in React's StrictMode to highlight potential problems in the application during development
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
