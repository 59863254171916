/**
 * Checks if the selected text is within a Markdown link.
 *
 * @param text - The entire text.
 * @param selectionStart - The start index of the selection.
 * @param selectionEnd - The end index of the selection.
 * @returns A boolean indicating whether the selection is within a Markdown link.
 */
const isSelectionInMarkdownLink = (
  text: string,
  selectionStart: number,
  selectionEnd: number
): boolean => {
  // Search backwards for the nearest preceding "](" to the selection start
  let sliceStart = text.substring(0, selectionStart);
  let openParenIndex = sliceStart.lastIndexOf("](");

  // If "](" found, find the corresponding opening "["
  if (openParenIndex !== -1) {
    let precedingTextToOpenParen = sliceStart.substring(0, openParenIndex);
    let openBracketIndex = precedingTextToOpenParen.lastIndexOf("[");

    // If no opening "[" or if "]" is found between "[" and "](", it's not a valid markdown link
    if (
      openBracketIndex === -1 ||
      precedingTextToOpenParen.includes("]", openBracketIndex)
    ) {
      return false;
    }

    // Search forward for the nearest following ")" from the selection end
    let sliceEnd = text.substring(selectionEnd);
    let closeParenIndex = sliceEnd.indexOf(")");

    // If ")" found and it's the correct closing parenthesis for the found "]("
    if (closeParenIndex !== -1) {
      let markdownLinkURL = text.substring(
        openParenIndex + 2,
        selectionEnd + closeParenIndex
      );

      // Additional check: Ensure there's no other "](" within the URL which could indicate malformed markdown or another link
      if (markdownLinkURL.includes("](")) {
        return false;
      }

      // The selection is within the markdown link's URL
      return true;
    }
  }

  // Default case: The selection is not within a markdown link's URL
  return false;
};

export default isSelectionInMarkdownLink;
