import React, { useState, FormEvent } from "react";
import Overlay from "../OverlayNew";
import { useAuthStatus } from "../Context/kv/useAuthStatus";
import { useKvDataContext } from "../Context/kvServer";
import { Container, HeadSplit, Body } from "../Overlay/column";
import { Settings } from "../Settings";
import { useHotkeys } from "react-hotkeys-hook";

import "./styles.css";

// Define the props for LoginComponent
interface LoginComponentProps {
  domain: string;
  handleClose: () => void;
}

// LoginComponent is a component for user login
const LoginComponent: React.FC<LoginComponentProps> = ({
  domain,
  handleClose,
}) => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const { loading, error, login } = useKvDataContext();

  useHotkeys("esc", () => {
    handleClose();
  });

  // handleSubmit is the function to handle form submission
  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    try {
      await login(username, password, domain, handleClose);
      // handle successful login
    } catch (err) {
      // handle error
    }
  };

  if (loading) return <p>Loading...</p>;

  return (
    <Container>
      <HeadSplit>
        <h2>Login</h2>
        <button onClick={handleClose}>❌</button>
      </HeadSplit>
      <Body>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Username"
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
          <button className="button" type="submit">
            Login
          </button>

          {error && (
            <h2>
              {error.response?.data?.error
                ? error.response?.data?.error
                : error.message}
            </h2>
          )}
        </form>
      </Body>
    </Container>
  );
};

// Define the props for Settings
export interface SettingsProps {
  handleClose: () => void;
}
interface ErrorResponse {
  response?: {
    status: number;
  };
}

export type ErrorType = Error & ErrorResponse;

// LoginWrapper is a component that wraps LoginComponent and Settings
const LoginWrapper: React.FC = () => {
  const [isOverlayVisible, setIsOverlayVisible] = useState<boolean>(false);

  const { isLoggedIn, loading, error } = useAuthStatus("editor");

  if (loading) return <p>Loading...</p>;
  if (!isOverlayVisible && error) return <p>Error: {error.message}</p>;

  const handleButtonClick = () => {
    setIsOverlayVisible(true);
  };

  const handleClose = () => {
    setIsOverlayVisible(false);
  };

  if (!isLoggedIn && !isOverlayVisible) {
    return (
      <>
        <button
          onClick={handleButtonClick}
          //   disabled={!editorHasFocus}
        >
          Login
        </button>
      </>
    );
  }

  if (isLoggedIn && !isOverlayVisible) {
    return (
      <>
        <button
          className="small"
          onClick={handleButtonClick}
          //   disabled={!editorHasFocus}
        >
          Settings
        </button>
      </>
    );
  }

  return (
    <>
      <Overlay>
        {!isLoggedIn && (
          <LoginComponent handleClose={handleClose} domain="editor" />
        )}
        {isLoggedIn && <Settings handleClose={handleClose} />}
      </Overlay>
    </>
  );
};

export default LoginWrapper;
