import { useEffect } from "react";
import { useKvDataContext } from "../kvServer";

export const useAuthStatus = (domain) => {
  const { isLoggedIn, loading, error, checkAuthStatus } = useKvDataContext();

  useEffect(() => {
    // Call checkAuthStatus only if not already checked
    // if (!isLoggedIn && !loading) {
    checkAuthStatus(domain);
    // }
  }, [checkAuthStatus, domain]);

  return { isLoggedIn, loading, error };
};
