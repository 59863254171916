import React, { useEffect, useState } from "react";

const FontLoaded = ({ children }) => {
  const [fontsLoaded, setFontsLoaded] = useState(false);
  useEffect(() => {
    Promise.all([
      document.fonts.load('10pt "Work Sans"'),
      document.fonts.load('10pt "IBM Plex Mono"'),
      document.fonts.load('10pt "Noto Emoji"'),
      // Add any other font loads here
    ]).then(() => {
      setFontsLoaded(true);
    });
  }, []);

  if (!fontsLoaded) {
    return (
      <div style={{ textAlign: "center" }}>
        <br />
        Loading fonts...
      </div>
    );
  }

  return <>{children}</>;
};

export default FontLoaded;
