import React from "react";

import "./styles.css";

const ShortcutHelp = ({ command }) => {
  return (
    <div className="shortcut-helpOLD">
      <div className="shortcut-helpOLD__inner">
        <div className="shortcut-helpOLD__text">
          {command.replace("meta+", "⌘+").toUpperCase()}
        </div>
      </div>
    </div>
  );
};

export default ShortcutHelp;
