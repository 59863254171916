import React, { useState, useRef } from "react";
import Editor from "./components/Editor/";
import { useHotkeys } from "react-hotkeys-hook";
import { KvDataProvider } from "./components/Context/kvServer";
// import Test from "./components/Test";

const App = () => {
  const [showShortKey, setShowShortKey] = useState(false);
  const timeoutIdRef = useRef(null); // useRef to keep track of the timeout ID
  const delay = 2000; // Delay in milliseconds

  useHotkeys(
    "meta",
    () => {
      // Clear any existing timeout
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }

      // Set a new timeout
      timeoutIdRef.current = setTimeout(() => {
        setShowShortKey(true);
      }, delay);
    },
    {
      keydown: true,
      keyup: false,
      enableOnFormTags: true,
    }
  );

  useHotkeys(
    "meta",
    () => {
      // Clear the timeout if the key is released before the delay
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = null;
      }

      // Immediately set showShortKey to false when the key is released
      setShowShortKey(false);
    },
    {
      keydown: false,
      keyup: true,
      enableOnFormTags: true,
    }
  );

  return (
    <div className={`app app--${showShortKey ? "show" : "hide"}-keyboard`}>
      <KvDataProvider>
        {/* <Test /> */}
        <Editor />
      </KvDataProvider>
    </div>
  );
};

export default App;
