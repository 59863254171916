// // setup Provider
// import { EditorContextProvider } from "components/Context/editor.jsx";
//
// <EditorContextProvider>
//   <Root />
// </EditorContextProvider>

// // use in components
// import { EditorContext } from "components/Context/editor.jsx";
// const [state, dispatch] = useContext(EditorContext);

import React, { useReducer, createContext, useEffect, useState } from "react";

const urlParams = new URLSearchParams(window.location.search);
const currentID = urlParams.get("id") ?? "default";
const storageID = urlParams.get("id") ? "id" : "current";

const textID = `notzer.editor.${storageID}.text`;
const selectionStartID = `notzer.editor.${storageID}.selectionStart`;
const selectionEndID = `notzer.editor.${storageID}.selectionEnd`;
const selectionDirectionID = `notzer.editor.${storageID}.selectionDirection`;

const HISTORY_LIMIT = 1000;
const HISTORY_TIME_GAP = 200;

const initialText = localStorage.getItem(textID) ?? `Your text here...`;
const initialSelectionStart = localStorage.getItem(selectionStartID) ?? 0;
const initialSelectionEnd =
  localStorage.getItem(selectionEndID) ?? initialText.length;
const initialSelectionDirection =
  localStorage.getItem(selectionDirectionID) ?? "none";

const initialEditor = {
  text: initialText,
  selectionStart: initialSelectionStart,
  selectionEnd: initialSelectionEnd,
  selectionDirection: initialSelectionDirection,
  timestamp: Date.now(),
};

const initialState = {
  ...initialEditor,

  // keep last 100 edits
  history: {
    stack: [{ ...initialEditor }],
    offset: 0,
  },
  editorHasFocus: true,
  // openAIKey,
};

function recordChange(state, obj) {
  const { stack, offset } = state.history;

  // new record
  const record = {
    text: obj.text ?? state.text,
    selectionStart: obj.selectionStart ?? state.selectionStart,
    selectionEnd: obj.selectionEnd ?? state.selectionEnd,
    selectionDirection: obj.selectionDirection ?? state.selectionDirection,
  };

  const history = {
    stack: [...stack],
    offset: offset,
  };

  if (stack.length && offset > -1) {
    // When something updates, drop the redo operations
    history.stack = stack.slice(0, offset + 1);

    // Limit the history length
    const count = history.stack.length;

    if (count > HISTORY_LIMIT) {
      const extras = count - HISTORY_LIMIT;

      history.stack = stack.slice(extras, count);
      history.offset = Math.max(history.offset - extras, 0);
    }
  }

  const timestamp = Date.now();

  const last = history.stack[history.offset];

  // A previous entry exists and was in short interval
  if (last && timestamp - last.timestamp < HISTORY_TIME_GAP) {
    // The last word of the previous line and current line match
    // Overwrite previous entry so that undo will remove whole word
    history.stack[history.offset] = { ...record, timestamp: last.timestamp };
  } else {
    history.stack.push({ ...record, timestamp });
    history.offset++;
  }

  return {
    ...state,
    ...record,
    history,
  };
}

function reducer(state, action) {
  switch (action.type) {
    case "SET_FOCUS": {
      const editorHasFocus = action.payload;

      return {
        ...state,
        editorHasFocus,
      };
    }

    case "ADD_PREFIX_POSTFIX": {
      const { prefix, postfix } = action.payload;
      const { selectionStart, selectionEnd } = state;

      const textBefore = state.text.slice(0, selectionStart);
      const selectedText = state.text.slice(selectionStart, selectionEnd);
      const textAfter = state.text.slice(selectionEnd);

      const updatedText =
        textBefore + prefix + selectedText + postfix + textAfter;

      // Update the selection to maintain the original text
      const newSelectionStart = selectionStart + prefix.length;
      const newSelectionEnd = newSelectionStart + selectedText.length;

      return recordChange(state, {
        text: updatedText,
        selectionStart: newSelectionStart,
        selectionEnd: newSelectionEnd,
      });
    }

    case "UNDO": {
      const { stack, offset } = state.history;

      if (stack[offset - 1]) {
        // Get the previous edit
        const { timestamp, ...record } = stack[offset - 1];

        // Apply the changes and update the offset
        return {
          ...state,
          ...record,
          history: {
            ...state.history,
            offset: Math.max(offset - 1, 0),
          },
        };
      }

      return state;
    }

    case "REDO": {
      const { stack, offset } = state.history;

      // Get the next edit
      if (stack[offset + 1]) {
        const { timestamp, ...record } = stack[offset + 1];

        if (record) {
          // Apply the changes and update the offset
          return {
            ...state,
            ...record,
            history: {
              ...state.history,
              offset: Math.min(offset + 1, stack.length - 1),
            },
          };
        }
      }

      return state;
    }

    case "SET_SELECTION": {
      const { selectionStart, selectionEnd, selectionDirection } =
        action.payload;

      return recordChange(state, {
        selectionStart,
        selectionEnd,
        selectionDirection,
      });
    }

    case "SET_TEXT_AND_SELECTION": {
      const { text, selectionStart, selectionEnd, selectionDirection } =
        action.payload;
      //   return { ...state, text, selectionStart, selectionEnd };

      return recordChange(state, {
        text,
        selectionStart,
        selectionEnd,
        selectionDirection,
      });
    }

    case "REPLACE_SELECTION": {
      const { selectionStart, selectionEnd } = state;
      const textBefore = state.text.slice(0, selectionStart);
      const textAfter = state.text.slice(selectionEnd);
      const newText = action.payload.text;
      const updatedText = textBefore + newText + textAfter;

      return recordChange(state, {
        text: updatedText,
        selectionStart: action.payload.selected
          ? selectionStart
          : selectionStart + newText.length,
        selectionEnd: selectionStart + newText.length,
      });
    }
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
}

export const EditorContext = createContext();

export const EditorContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    localStorage.setItem(textID, state.text);

    if (isInitialized) {
      // Only send messages if initialized
      const parentWindow = window.parent || window.opener; // iframe or opener window
      if (parentWindow) {
        parentWindow.postMessage(
          {
            type: "SET_TEXT_AND_SELECTION",
            id: currentID,
            payload: {
              text: state.text,
              selectionStart: state.selectionStart,
              selectionEnd: state.selectionEnd,
              selectionDirection: state.selectionDirection,
            },
          },
          "*"
        );
      }
    }
  }, [
    isInitialized,
    state.text,
    state.selectionStart,
    state.selectionEnd,
    state.selectionDirection,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    () => {
      // Function to handle the message event
      const handleMessage = (e) => {
        if (e.data) {
          try {
            const data = e.data;

            if (data.type === "INIT" && currentID === data.id) {
              setIsInitialized(true);

              dispatch({
                type: "SET_TEXT_AND_SELECTION",
                payload: {
                  text: data.payload.text,
                  selectionStart: data.payload.selectionStart,
                  selectionEnd: data.payload.selectionEnd,
                  selectionDirection: data.payload.selectionDirection,
                },
              });
            } else if (
              isInitialized &&
              currentID === data.id &&
              data.type === "SET_TEXT_AND_SELECTION" &&
              data.payload.text
            ) {
              if (data.payload.text !== state.text) {
                dispatch({
                  type: "SET_TEXT_AND_SELECTION",
                  payload: {
                    text: data.payload.text,
                    selectionStart: data.payload.selectionStart,
                    selectionEnd: data.payload.selectionEnd,
                    selectionDirection: data.payload.selectionDirection,
                  },
                });
              }
            }
          } catch (error) {
            console.warn(error);
          }
        }
      };

      // Add event listener
      window.addEventListener("message", handleMessage);

      // notify parent window that editor is ready
      const parentWindow = window.parent || window.opener; // iframe or opener window
      if (parentWindow) {
        parentWindow.postMessage(
          {
            type: "READY",
            id: currentID,
          },
          "*"
        );
      }

      // Cleanup function
      return () => {
        window.removeEventListener("message", handleMessage);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    localStorage.setItem(selectionStartID, state.selectionStart);
  }, [state.selectionStart]);
  useEffect(() => {
    localStorage.setItem(selectionEndID, state.selectionEnd);
  }, [state.selectionEnd]);
  useEffect(() => {
    localStorage.setItem(selectionDirectionID, state.selectionDirection);
  }, [state.selectionDirection]);

  return (
    <EditorContext.Provider value={[state, dispatch]}>
      {children}
    </EditorContext.Provider>
  );
};
