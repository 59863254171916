import React, { useContext } from "react";
import { EditorContext } from "../Context/editor.jsx";
import "./segmenter.js";
import "./styles.css";

function findPositionInText(text, index) {
  if (index > text.length) {
    index = text.length;
    // return { line: -1, position: -1 }; // Index out of bounds
  }

  let line = 1;
  let position = 1; // start position from 1 for each line

  for (let i = 0; i < text.length; i++) {
    if (i === index) {
      return { line, position };
    }

    if (text[i] === "\n" || text[i] === "\r") {
      // To handle the case of '\r\n' (Windows line endings)
      if (text[i] === "\r" && text[i + 1] === "\n") {
        i++;
      }
      line++;
      position = 0;
    } else {
      position++;
    }
  }

  // If the index is at the end of the text
  if (index === text.length) {
    return { line, position };
  }

  return { line: -1, position: -1 }; // Index not found
}

const Counter = ({ debug }) => {
  const [state] = useContext(EditorContext);

  // console.time("Word Calculation");

  const segmenterWord = new Intl.Segmenter("en", { granularity: "word" });
  const segmentsWords = segmenterWord.segment(state.text);
  const countWords = [...segmentsWords].filter(
    (item) => item.isWordLike
  ).length;
  // console.timeEnd("Word Calculation");

  // console.time("char Calculation");

  const segmenterChar = new Intl.Segmenter("en", { granularity: "grapheme" });
  const segmentsChars = segmenterChar.segment(state.text);
  const countChars = [...segmentsChars].length;

  // console.timeEnd("char Calculation");

  const normalizedStart = Math.min(state.selectionStart, state.selectionEnd);
  //   const normalizedEnd = Math.max(state.selectionStart, state.selectionEnd);

  // console.time("findPositionInText");
  const { line, position } = findPositionInText(state.text, normalizedStart);
  // console.timeEnd("findPositionInText");

  const isPolyfill = segmenterWord.isPolyfill;

  return (
    <div className="counter">
      {debug && (
        <div className="counter__group">
          <span className="counter__group-title">Selection: </span>
          <span className="counter__group-text">
            Start:&nbsp;{state.selectionStart}{" "}
          </span>
          <span className="counter__group-text">
            End:&nbsp;{state.selectionEnd}{" "}
          </span>
          <span className="counter__group-text">
            Direction:&nbsp;{state.selectionDirection}{" "}
          </span>
          <span className="counter__group-text">
            Focus:&nbsp;{state.editorHasFocus ? "true" : "false"}
          </span>
        </div>
      )}

      <div className="counter__group">
        <span className="counter__group-title">File:</span>
        <span className="counter__group-text"> Ln:&nbsp;{line}</span>
        <span className="counter__group-text"> Col:&nbsp;{position}</span>
        <span className="counter__group-text">
          {" "}
          Idx:&nbsp;{normalizedStart}
        </span>
      </div>

      <div className="counter__group">
        <span className="counter__group-title">Stats:</span>
        <span className="counter__group-text">
          {" "}
          Words:&nbsp;{isPolyfill && "~"}
          {countWords}
        </span>
        <span className="counter__group-text">
          {" "}
          Chars:&nbsp;{isPolyfill && "~"}
          {countChars}
        </span>
      </div>

      {/* <div className="counter_caret">Caret:&nbsp;{location}</div> */}
    </div>
  );
};

export default Counter;
