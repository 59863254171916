import "./column.css"; // Make sure to create this CSS file

/*
<Container>
<Head></Head>
<Body></Body>
<Footer></Footer>
</Container> 
*/

export const Container = ({ children }) => {
  return <div className="column__container">{children}</div>;
};

export const Head = ({ children }) => (
  <div className="column__head">{children}</div>
);

export const HeadSplit = ({ children }) => (
  <div className="column__head column__head--split">{children}</div>
);

export const Body = ({ children }) => (
  <div className="column__body">{children}</div>
);

export const Footer = ({ children }) => (
  <div className="column__footer">{children}</div>
);
