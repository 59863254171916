import { EditorContextProvider } from "../Context/editor";
import Counter from "../Counter/";
import Textfield from "../Textfield/";
import Assistant from "../AssistantNew/";
import AssistantOld from "../Assistant/";
import Login from "../Login/";

import "./styles.css";

const debug = process.env.NODE_ENV === "development";

const Editor = () => {
  return (
    <EditorContextProvider>
      <div className="editor">
        <div className="editor__header">
          <div className="editor__header-inner">
            <AssistantOld />
            <Login />
          </div>
        </div>
        <div className="editor__content">
          <Textfield />
          {debug && <Assistant />}
        </div>
        <div className="editor__footer">
          <Counter debug={debug} />
          {/* <History /> */}
        </div>
      </div>
    </EditorContextProvider>
  );
};

export default Editor;
