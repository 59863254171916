import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import axios from "axios";

const KvDataContext = createContext();
const DOMAIN = "https://kv.srv.signalwerk.ch";

export const instance = axios.create({
  baseURL: DOMAIN,
});

export const KvDataProvider = ({ children }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [jwt, setJwt] = useState(localStorage.getItem("jwtToken") || null); // Store JWT in state
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const updateData = (key, value) => {
    setData((prevData) => ({ ...prevData, [key]: value }));
  };

  const setToken = useCallback((token) => {
    localStorage.setItem("jwtToken", token); // Save to local storage for persistence
    setJwt(token); // Update JWT in state
    setAuthHeader(token);
  }, []);

  const clearToken = () => {
    localStorage.removeItem("jwtToken");
    setJwt(null); // Clear JWT from state
    setAuthHeader(null);
    setIsLoggedIn(false);
  };

  const setAuthHeader = (token) => {
    if (token) {
      instance.defaults.headers.common["Authorization"] = "Bearer " + token;
    } else {
      delete instance.defaults.headers.common["Authorization"];
    }
  };

  const login = async (username, password, domain, cb) => {
    setLoading(true);
    setError(null);
    try {
      const response = await instance.post(`/${domain}/login`, {
        username,
        password,
      });
      const { token } = response.data;
      setToken(token);
      setLoading(false);
      setIsLoggedIn(true);
      if (cb) {
        cb(response);
      }
      return response.data;
    } catch (err) {
      setToken(null);
      setError(err);
      setLoading(false);
      setIsLoggedIn(false);
      throw err;
    }
  };

  const checkAuthStatus = useCallback(
    async (domain) => {
      if (!jwt) {
        // Check if JWT is not empty
        setIsLoggedIn(false);
        return;
      }

      setLoading(true);
      try {
        const response = await instance.get(`/${domain}/users/me`);
        setIsLoggedIn(response.data.isLoggedIn);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    },
    [jwt],
  ); // Depend on jwt state

  useEffect(() => {
    const domain = "editor";

    if (jwt) {
      setAuthHeader(jwt);
      checkAuthStatus(domain);
    }
  }, [jwt, checkAuthStatus]); // run on startup

  const logout = () => {
    setIsLoggedIn(false);
    clearToken();
  };

  return (
    <KvDataContext.Provider
      value={{
        data,
        loading,
        error,
        isLoggedIn,
        jwt, // Expose jwt if needed outside
        login,
        checkAuthStatus,
        updateData,
        logout,
      }}
    >
      {children}
    </KvDataContext.Provider>
  );
};

export const useKvDataContext = () => useContext(KvDataContext);
