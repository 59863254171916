import React from "react";
import { Container, HeadSplit, Body, Footer } from "../Overlay/column";
import { SettingsProps } from "../Login";
import { useHotkeys } from "react-hotkeys-hook";
import { useKvDataContext } from "../Context/kvServer";

import { InputField } from "./InputField";

// Settings is a component for displaying and updating key-value data
export const Settings: React.FC<SettingsProps> = ({ handleClose }) => {
  const domain = "editor"; // Replace with your domain

  const { logout } = useKvDataContext();

  useHotkeys("esc", () => {
    handleClose();
  });

  const handleLogout = () => {
    logout();
    handleClose();
  };

  return (
    <Container>
      <HeadSplit>
        <h2>Settings</h2>
        <button onClick={handleClose}>❌</button>
      </HeadSplit>
      <Body>
        <div>
          <InputField
            label="OpenAI AIPI Key"
            id="assistant.ai.api.openai.key"
            domain={domain}
          />
        </div>
      </Body>
      <Footer>
        <button className="button" onClick={handleLogout}>
          Logout
        </button>
      </Footer>
    </Container>
  );
};
