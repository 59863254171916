import React, { useState, useEffect, ChangeEvent } from "react";
import { useDomainData } from "../Context/kv/useDomainData";
import { ErrorType } from "../Login";

// Define the props for the InputField component
interface InputFieldProps {
  id: string; // The key for the key-value pair
  domain: string; // The domain for the key-value pair
  label: string; // The label for the input field
}

/**
 * InputField is a component for displaying and updating key-value data.
 * It accepts a key and a domain as props.
 */
export const InputField: React.FC<InputFieldProps> = ({
  id,
  domain,
  label,
}) => {
  const { value, loading, error, updateValue } = useDomainData(domain, id);
  const [inputValue, setInputValue] = useState<string>("");

  useEffect(() => {
    if (value && !loading) {
      setInputValue(value);
    }
  }, [value, loading]);

  if (loading) return <p>Loading...</p>;

  if (error && (error as ErrorType).response?.status !== 404) {
    return <p>Error: {(error as ErrorType).message}</p>;
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleUpdate = () => {
    updateValue(inputValue);
  };

  return (
    <div>
      {/* <h3>Data for id: {id}</h3>
      <p>Value: {value}</p> */}

      <label htmlFor={id}>{label}</label>
      <input
        type="text"
        id={id}
        value={inputValue}
        onChange={handleInputChange}
      />

      <button className="button" onClick={handleUpdate}>
        Update Value
      </button>
    </div>
  );
};
