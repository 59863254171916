// parts inspired by
// https://github.com/satya164/react-simple-code-editor#readme

import React, { useContext, useEffect, useRef } from "react";
import { EditorContext } from "../Context/editor.jsx";
import isSelectionInMarkdownLink from "./isSelectionInMarkdownLink.ts";

// import RenderLines from "components/RenderLines/index.jsx";

import FontLoaded from "./FontLoaded.jsx";

import "./styles.css";

const isWindows = "navigator" in global && /Win/i.test(navigator.platform);
const isMacLike =
  "navigator" in global && /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);

const KEY_TAB = "Tab";
const KEY_ESCAPE = "Escape";

const isValidHttpUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
};

function isCmd(event, key) {
  const isCorrectKey = event.key === key;
  const isModifierKeyActive =
    (isMacLike && event.metaKey) || (!isMacLike && event.ctrlKey);
  const isOtherKeyActive = event.shiftKey || event.altKey;

  return isCorrectKey && isModifierKeyActive && !isOtherKeyActive;
}

const Textfield = () => {
  const [state, dispatch] = useContext(EditorContext);

  const textareaRef = useRef(null);

  useEffect(() => {
    // console.log("textareaRef update");
    if (textareaRef.current) {
      // console.log("textareaRef has current");

      if (
        state.editorHasFocus &&
        textareaRef?.current !== document.activeElement
      ) {
        textareaRef.current.focus();
      }

      // if text is different from the current value, update it
      if (textareaRef.current.value !== state.text) {
        // console.log("update value");
        textareaRef.current.value = state.text;
        textareaRef.current.setSelectionRange(
          state.selectionStart,
          state.selectionEnd,
          state.selectionDirection
        );
      } else if (
        // if range is different from the current selection, update it
        textareaRef.current.selectionStart !== state.selectionStart ||
        textareaRef.current.selectionEnd !== state.selectionEnd ||
        textareaRef.current.selectionDirection !== state.selectionDirection
      ) {
        console.log("update selection");
        textareaRef.current.setSelectionRange(
          state.selectionStart,
          state.selectionEnd,
          state.selectionDirection
        );
      }
    }
  }, [
    state.text,
    state.selectionStart,
    state.selectionEnd,
    state.selectionDirection,
    state.editorHasFocus,
  ]);

  const handleTextSelection = (event) => {
    const { selectionStart, selectionEnd, selectionDirection } = event.target;

    if (selectionStart !== state.selectionStart) {
      // console.log(
      //   "handleTextSelection selectionStart",
      //   selectionStart,
      //   state.selectionStart
      // );
    }
    if (selectionEnd !== state.selectionEnd) {
      // console.log(
      //   "handleTextSelection selectionEnd",
      //   selectionEnd,
      //   state.selectionEnd
      // );
    }
    if (selectionDirection !== state.selectionDirection) {
      // console.log(
      //   "handleTextSelection selectionDirection",
      //   selectionDirection,
      //   state.selectionDirection
      // );
    }

    // if selection changed
    if (
      selectionStart !== state.selectionStart ||
      selectionEnd !== state.selectionEnd ||
      selectionDirection !== state.selectionDirection
    ) {
      dispatch({
        type: "SET_SELECTION",
        payload: { selectionStart, selectionEnd, selectionDirection },
      });
    }
  };

  const handleChange = (event) => {
    console.log("handleChange");

    // const text = event.target.value;
    const { value, selectionStart, selectionEnd, selectionDirection } =
      event.target;

    // if something changed
    if (
      value !== state.text ||
      selectionStart !== state.selectionStart ||
      selectionEnd !== state.selectionEnd ||
      selectionDirection !== state.selectionDirection
    ) {
      dispatch({
        type: "SET_TEXT_AND_SELECTION",
        payload: {
          text: value,
          selectionStart,
          selectionEnd,
          selectionDirection,
        },
      });
    }
  };

  const handlePaste = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("text");
    const { text, selectionStart, selectionEnd } = state;

    const selectedText = text.substring(selectionStart, selectionEnd);

    // Allow normal paste if the selected text is a URL or part of a markdown link
    if (isSelectionInMarkdownLink(text, selectionStart, selectionEnd)) {
      return; // Exit and let the browser handle the paste normally
    }

    if (isValidHttpUrl(pastedData) && selectionStart !== selectionEnd) {
      event.preventDefault();
      const markdownLink = `[${selectedText}](${pastedData})`;
      const before = text.substring(0, selectionStart);
      const after = text.substring(selectionEnd);

      // Update the state with the new text including the markdown link
      dispatch({
        type: "SET_TEXT_AND_SELECTION",
        payload: {
          text: before + markdownLink + after,
          selectionStart,
          selectionEnd: selectionStart + markdownLink.length,
        },
      });
    }
  };

  const handleKeyDown = async (event) => {
    const { selectionStart, selectionEnd /* selectionDirection */ } =
      event.target;

    if (event.key === KEY_ESCAPE) {
      event.target.blur();
      return;
    }

    if (event.key === KEY_TAB) {
      // Prevent focus change
      event.preventDefault();

      dispatch({ type: "REPLACE_SELECTION", payload: { text: "\t" } });
      return;
    }

    if (selectionStart !== selectionEnd && event.key === "(") {
      event.preventDefault();

      dispatch({
        type: "ADD_PREFIX_POSTFIX",
        payload: {
          prefix: "(",
          postfix: ")",
        },
      });
      return;
    }

    if (selectionStart !== selectionEnd && event.key === "[") {
      event.preventDefault();

      dispatch({
        type: "ADD_PREFIX_POSTFIX",
        payload: {
          prefix: "[",
          postfix: "]",
        },
      });
      return;
    }
    if (selectionStart !== selectionEnd && event.key === "{") {
      event.preventDefault();

      dispatch({
        type: "ADD_PREFIX_POSTFIX",
        payload: {
          prefix: "{",
          postfix: "}",
        },
      });
      return;
    }
    if (selectionStart !== selectionEnd && event.key === '"') {
      event.preventDefault();

      dispatch({
        type: "ADD_PREFIX_POSTFIX",
        payload: {
          prefix: '"',
          postfix: '"',
        },
      });
      return;
    }
    if (selectionStart !== selectionEnd && event.key === "'") {
      event.preventDefault();

      dispatch({
        type: "ADD_PREFIX_POSTFIX",
        payload: {
          prefix: "'",
          postfix: "'",
        },
      });
      return;
    }
    // if (selectionStart !== selectionEnd && event.key === "Dead") {
    //   event.preventDefault();

    //   dispatch({
    //     type: "ADD_PREFIX_POSTFIX",
    //     payload: {
    //       prefix: "`",
    //       postfix: "`",
    //     },
    //   });
    //   return;
    // }
    if (selectionStart !== selectionEnd && event.key === "«") {
      event.preventDefault();

      dispatch({
        type: "ADD_PREFIX_POSTFIX",
        payload: {
          prefix: "«",
          postfix: "»",
        },
      });
      return;
    }

    if (isCmd(event, "z")) {
      event.preventDefault();
      dispatch({ type: "UNDO" });
      return;
    }
    if (isCmd(event, "b")) {
      event.preventDefault();

      dispatch({
        type: "ADD_PREFIX_POSTFIX",
        payload: {
          prefix: "**",
          postfix: "**",
        },
      });
      return;
    }

    if (
      (isMacLike
        ? // Trigger redo with ⌘+Shift+Z on Mac
          event.metaKey && event.key === "z" && event.shiftKey
        : isWindows
        ? // Trigger redo with Ctrl+Y on Windows
          event.ctrlKey && event.key === "y"
        : // Trigger redo with Ctrl+Shift+Z on other platforms
          event.ctrlKey && event.key === "z" && event.shiftKey) &&
      !event.altKey
    ) {
      event.preventDefault();
      dispatch({ type: "REDO" });
      return;
    }
  };

  const handleFocus = () => {
    // console.log("handleFocus");
    dispatch({ type: "SET_FOCUS", payload: true });
  };

  const handleBlur = () => {
    // console.log("handleBlur");
    dispatch({ type: "SET_FOCUS", payload: false });
  };

  return (
    <div className="textfield">
      <div className="textfield__inner">
        <FontLoaded>
          <textarea
            className="textarea textfield__textarea"
            value={state.text}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onSelect={handleTextSelection}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onPaste={handlePaste}
            ref={textareaRef}
          />
          <pre className="textarea textfield__text" aria-hidden="true">
            {state.text.split("\n").map((line, index, arr) => (
              <div key={index} className="textfield__text-line">
                <span className="textfield__text-line-nr">{index}</span>
                {line}
                <wbr />
                <span className="textfield__text-nl">
                  {index !== arr.length - 1 ? "¶" : ""}
                </span>
              </div>
            ))}
          </pre>
        </FontLoaded>

        {/* <pre className="textarea textfield__text" aria-hidden="true">
          {state.text.split("\n").map((line) => (
            <RenderLines text={line} />
          ))}
        </pre> */}
      </div>
    </div>
  );
};

export default Textfield;
