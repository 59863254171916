// Check if Intl.Segmenter is available
if (typeof Intl.Segmenter === "undefined") {
  // Polyfill for Intl.Segmenter
  class Segmenter {
    constructor(locale, options) {
      this.locale = locale;
      this.options = options;
      this.isPolyfill = true; // Unique property
    }

    segment(text) {
      if (this.options.granularity === "word") {
        return this.segmentWords(text);
      } else if (this.options.granularity === "grapheme") {
        return this.segmentGraphemes(text);
      } else {
        throw new Error("Unsupported granularity");
      }
    }

    segmentWords(text) {
      const regex =
        /([\p{Alphabetic}\p{Mark}\p{Decimal_Number}\p{Connector_Punctuation}\p{Join_Control}]+)/gu;
      const matches = text.match(regex) || [];
      return matches.map((match) => ({ isWordLike: true, segment: match }));
    }

    segmentGraphemes(text) {
      // Match all extended pictographic characters or sequences
      const regex =
        /(\p{Extended_Pictographic}(\u200D\p{Extended_Pictographic})*)|[\s\S]/gu;

      const matches = text.match(regex) || [];
      return matches.map((match) => ({ segment: match }));
    }
  }

  // Assign the polyfill to Intl.Segmenter
  Intl.Segmenter = Segmenter;
}
