import React, { ReactNode } from "react";
import "./styles.css";

// Define the props for the Overlay component
interface OverlayProps {
  children: ReactNode; // The child elements to be rendered within the Overlay
  onClose?: () => void; // Optional function to be called when the Overlay is closed
}

/**
 * Overlay is a component that renders an overlay on the screen.
 * It accepts child elements and an optional onClose function as props.
 */
const Overlay: React.FC<OverlayProps> = ({ children, onClose }) => {
  return (
    <div className="overlay">
      <div className="overlay__catch" onClick={onClose}></div>
      <div className="overlay__inner">{children}</div>
    </div>
  );
};

export default Overlay;
